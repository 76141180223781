import KonnexionRending from './KONNEXION_RENEWAL.png';
import Router from "./Router";
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={KonnexionRending} className="App-logo" alt="logo"/>
            </header>
            <Router/>
        </div>
    );
}

export default App;
