import {BrowserRouter, Routes, Route} from "react-router-dom"
import App from "./App";


function Router() {
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={App}/>
                <Route element={App}/>
            </Routes>
        </BrowserRouter>
    </>
}

export default Router;